import React from "react";
import HomePageLayout from "../components/HomeLayout";
import About from "../components/About";
import Portfolio from "../components/Portfolio";

const IndexPage = () => {
  return (
    <HomePageLayout>
      <About />
      <Portfolio />
    </HomePageLayout>
  );
};

export default IndexPage;

import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import LandingLogo from "./LandingLogo";

const HomePageLayout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  return (
    <div>
      <title>{data.site.siteMetadata.title}</title>
      <LandingLogo />
      {children}
    </div>
  );
};

export default HomePageLayout;

import React from "react";
import PortfolioCard from "../components/PortfolioCard";
import { useStaticQuery, graphql } from "gatsby";

const Portfolio = () => {
  const data = useStaticQuery(graphql`
    query PortfolioQuery {
      allMarkdownRemark(
        sort: { fields: frontmatter___date, order: DESC }
        filter: {
          fileAbsolutePath: { regex: "/(portfolio)/" }
          frontmatter: { portfolio: { eq: true } }
        }
      ) {
        nodes {
          html
          frontmatter {
            project_title
            work_title
            date
            project_url
            description
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `).allMarkdownRemark.nodes;
  return (
    <div>
      {data.map((markdown) => (
        <PortfolioCard
          projectTitle={markdown.frontmatter.project_title}
          projectURL={markdown.frontmatter.project_url}
          workTitle={markdown.frontmatter.work_title}
          projectImage={markdown.frontmatter.image}
          projectDescription={markdown.frontmatter.description}
          key={markdown.frontmatter.work_title}
        />
      ))}
    </div>
  );
};

export default Portfolio;

import React from "react";
import "../css/styles.css";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const PortfolioCard = ({
  projectTitle,
  workTitle,
  projectImage,
  projectDescription,
  projectURL,
}) => {
  const image = getImage(projectImage);
  console.log(projectURL);
  return (
    <div className="portfolio-card">
      <div className="project-graphic-container">
        <h2>
          <a className="h2-link" href={projectURL}>
            {workTitle}
          </a>
        </h2>
        <a className="image-link" href={projectURL}>
          <div className="portfolio-card__graphic__image-container">
            <GatsbyImage
              className="portfolio-card__graphic__image"
              image={image}
              alt=""
            />
          </div>
        </a>
      </div>
      <div className="project-info-container">
        <h2>
          <a className="h2-link" href={projectURL}>
            {projectTitle}
          </a>
        </h2>
        <p
          className="about-text"
          dangerouslySetInnerHTML={{ __html: projectDescription }}
        />
      </div>
    </div>
  );
};

export default PortfolioCard;

import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import "../css/styles.css";

const About = () => {
  const data = useStaticQuery(graphql`
    query AboutQuery {
      allMarkdownRemark(filter: { frontmatter: { title: { eq: "about" } } }) {
        nodes {
          html
        }
      }
    }
  `);
  const markdown = data.allMarkdownRemark.nodes;
  const text = markdown.map((markdown) => markdown.html);
  return (
    <div className="about-container">
      <div className="about-section">
        <h2>Info</h2>
        <div
          className="about-text"
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </div>
    </div>
  );
};

export default About;

import React from "react";
import "../css/styles.css";
import { StaticImage } from "gatsby-plugin-image";

const LandingLogo = () => {
  return (
    <div className="landinglogo-container">
      <StaticImage
        className="orb"
        src="../data/images/orb-small.png"
        alt="A grey and white orb"
        placeholder="transparent" //placeholder property casuses MaxListenersExceededWarning https://github.com/gatsbyjs/gatsby/issues/34795
      />
      <a className="image-link" href="/">
        <h1 className="logo-landing">Martin Disley</h1>
      </a>
      <a href="mailto:martin_disley[at]icloud.com">
        <button className="contact-button-landing">Contact</button>
      </a>
    </div>
  );
};

export default LandingLogo;
